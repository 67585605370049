<template>
  <div>
    <UserPriveleges />
  </div>
</template>
<script>
// @ is an alias to /src
import UserPriveleges from '@/components/Administration/UserPriveleges.vue'


export default {
  name: 'AdminView',
  components: {
    UserPriveleges
  }
}
</script>