<template>
  <div>
    <Strategiesview />
  </div>
</template>
<script>
// @ is an alias to /src
import Strategiesview from '@/components/Strategies/StrategyList.vue'


export default {
  name: 'Strategies',
  components: {
    Strategiesview
  }
}
</script>