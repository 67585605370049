<template>
    <v-card>
        <div class="pa-4 container-fluid">
            <div class="main-container">
                <div id="data-section" class="data-section" :style="dataSectionStyle">
                    <ApplyStrategy :cryptoId="cryptoId_detail" :scanfilter="scanFilter_detail" />
                    <CryptoPrices :cryptoId="cryptoId_detail" />
                </div>
                <div class="charts-container-all" :style="chartsContainerStyle">
                    <v-card class="mb-2">
                        <v-card-title class="headline">
                            <v-btn @click="toggleDataSection" class="toggle-button">Toggle</v-btn>
                        </v-card-title>
                    </v-card>
                    <div class="charts-container">
                        <TradingViewWidget chart="1" :exchange="exchange_detail" :symbol="symbol_detail" timeframe="D" />                        
                    </div>
                    </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import CryptoPrices from './CryptoPrices.vue';
import TradingViewWidget from './TradingViewWidget.vue';
import ApplyStrategy from './ApplyStrategy.vue';

export default {
    components: {
        CryptoPrices,
        TradingViewWidget,
        ApplyStrategy,
    },
    props: {
        exchange_detail: String,
        symbol_detail: String,
        cryptoId_detail: [String, Number],
        scanFilter_detail: [String, Number],
    },
    data() {
        return {
            isDataSectionExpanded: false
        }
    },
    computed: {
        dataSectionStyle() {
            return {
                width: this.isDataSectionExpanded ? '85%' : '15%'
            };
        },
        chartsContainerStyle() {
            return {
                width: this.isDataSectionExpanded ? '15%' : '85%'
            };
        }
    },
    methods: {
        toggleDataSection() {
            this.isDataSectionExpanded = !this.isDataSectionExpanded;
        }
    }
}
</script>
<style>
.container-fluid {
    padding: 0 20px;
}

.main-container {
    display: flex;
    height: 70vh;
    flex-direction: row;
    transition: all 0.5s ease;
}

.data-section, .charts-container-all {
    height: 100%;
    overflow-y: auto;
    transition: width 0.5s ease;
}

.data-section {
    background-color: #f4f4f4;
    border-right: 1px solid #ccc;
}

.charts-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(3, 1fr); 
    gap: 20px; 
    padding: 20px;
}

.v-card {
    box-shadow: none;
}

.toggle-button {
    background-color: #03dac6;
    color: #000;
    border: none;
}
</style>
