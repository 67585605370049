import { createStore } from 'vuex'
import axios from 'axios';
import { cryptoPricesModule } from './modules/cryptoprices';  // Import the module
import { strategyModule } from './modules/strategy';
import { scanModule } from './modules/scan';


const BASE_URL = 'https://tradeapi.firstcareoptical.co.uk/'// 'http://localhost:8002/'; // Update with your backend server's URL

export default createStore({
  state: {
    token: null,  // This will store the authentication token
    isAuthorized: false,  // This will store the user's authorization status
    isAdmin: false,        // This will store the user's admin status
    users: [],     // This will store the user data fetched from the backend
    backendBaseUrl: BASE_URL,  // Base URL for backend API
    userStatus: 'Awaiting Authorization', // Authorization status
    cryptos: [],
    cryptofilter:''
  },
  getters: {
    // Correctly define isLoggedIn as a getter
    isLoggedIn: state => !!state.token,
    // Getter for authorization status
    isAuthorized: state => state.isAuthorized,
    // Getter for admin status
    isAdmin: state => state.isAdmin
  },
  mutations: {
    // Mutation to set the token, isAuthorized, and isAdmin
    setUserData(state, { token, isAuthorized, isAdmin }) {
      state.token = token;
      state.isAuthorized = isAuthorized;
      state.isAdmin = isAdmin;
    },
    // Mutation to clear the token, isAuthorized, and isAdmin
    clearUserData(state) {
      state.token = null;
      state.isAuthorized = false;
      state.isAdmin = false;
    },
    // Mutation to set the user data
    setUsers(state, users) {
      state.users = users;
    },
    SET_CRYPTOS(state, cryptos) {
      state.cryptos = cryptos;
    }
  },
  actions: {
    // Action to log in the user and set the token, isAuthorized, and isAdmin
    login({ commit }, { token, isAuthorized, isAdmin }) {
      commit('setUserData', { token, isAuthorized, isAdmin });
    },
    // Action to log out the user and clear the token, isAuthorized, and isAdmin
    logout({ commit }) {
      commit('clearUserData');
    },
    // Action to fetch users from the backend
    async fetchUsers({ commit }) {
      try {
        const response = await axios.get('/users'); // Replace '/users' with your backend API endpoint for fetching users
        //console.log(response.data)
        commit('setUsers', response.data);    // Assuming your API response contains a 'users' array
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    // Action to authorize or deauthorize a user
    async authorizeUser({ dispatch }, { email, authorize }) {
      try {
        //console.log(email)
        //console.log(authorize)
        await axios.post('/authorize', {user_email: email, is_authorized: authorize });
        // Fetch updated user data after authorizing/deauthorizing user
        dispatch('fetchUsers');
      } catch (error) {
        console.error('Error authorizing user:', error);
      }
    },
    // Action to toggle admin status of a user
    async toggleAdmin({ dispatch }, { email, isAdmin }) {
      try {
        await axios.post('/admin', {user_email: email, is_admin: isAdmin });
        // Fetch updated user data after toggling admin status
        dispatch('fetchUsers');
      } catch (error) {
        console.error('Error toggling admin status:', error);
      }
    },
    async fetchCryptos({ commit }, { filter }) {
        try {
            const response = await axios.get('/cryptos', { params: { filter } });
            //console.log(response.data)
            commit('SET_CRYPTOS', response.data);
        } catch (error) {
            console.error('Error fetching cryptos:', error);
        }
    }
  },
  modules: {
    cryptoPricesModule,
    strategyModule,
    scanModule
  }
})
