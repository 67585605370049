import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import Login from '@/components/Registration/Login.vue';
import Register from '@/components/Registration/Register.vue';
import AdminDashboard from "../views/AdminDashboard.vue";
import CryptoView from "../views/Cryptos.vue";
import StrategyView from "../views/Strategy.vue";



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/admindashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/cryptoview',
    name: 'CryptoView',
    component: CryptoView,
    meta: { requiresAuth: true, requiresAdmin: false }
  },
  {
    path: '/strategyview',
    name: 'StrategyView',
    component: StrategyView,
    meta: { requiresAuth: true, requiresAdmin: false }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { requiresAuth: true }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { requiresAuth: true }
  },
  { 
    path: '/login', 
    name: 'Login',
    component: Login
  },
  { 
    path: '/register', 
    name: 'Register',
    component: Register 
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const isAuthorized = store.getters.isAuthorized;
  const isAdmin = store.getters.isAdmin;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next('/login');
    } else {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!isAdmin) {
          next('/');
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});


export default router
