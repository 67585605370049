import axios from 'axios';

export const cryptoPricesModule = {
  namespaced: true,  // Enable namespaced to access this module by namespace

  state: {
    cryptoPrices: {}
  },

  mutations: {
    SET_CRYPTO_PRICES(state, { cryptoId, prices }) {
      if (!state.cryptoPrices[cryptoId]) {
        state.cryptoPrices[cryptoId] = { prices: [] };
      }
      state.cryptoPrices[cryptoId].prices = prices;
      console.log(state.cryptoPrices[cryptoId].prices)
    }
  },

  actions: {
    async fetchCryptoPrices({ commit }, { cryptoId, timeframe }) {
      try {
        // Update the URL to include the `cryptoId` as a path parameter
        // and send `timeframe` as a query parameter.
        const response = await axios.get(`/crypto-prices/${cryptoId}`, {
          params: { timeframe }  // this ensures `timeframe` is passed as a query parameter
        });
        // Assuming the response contains the price data directly
        // and you have a mutation named `SET_CRYPTO_PRICES` defined in your Vuex store:

        commit('SET_CRYPTO_PRICES', { cryptoId: cryptoId, prices: response.data });
    
      } catch (error) {
        console.error('Error fetching crypto prices:', error);
        throw error; // Propagate error for handling in component
      }
    }    
  }
};