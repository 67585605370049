import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})
// Create the Vue application instance
const app = createApp(App);

// Set base URL for all axios requests
axios.defaults.baseURL = store.state.backendBaseUrl;
axios.defaults.headers.common['Authorization'] = store.state.token;

// Make axios available on the Vue instance globally
app.config.globalProperties.$axios = axios;

// Use plugins
app.use(store);
app.use(router);
app.use(vuetify);

// Mount the application
app.mount('#app');