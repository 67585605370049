<template>
    <v-container>
      <v-card>
        <v-card-title>Register</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="handleRegister">
            <v-text-field
              label="Email"
              v-model="email"
              :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
              required
            ></v-text-field>
            <v-text-field
              type="password"
              label="Password"
              v-model="password"
              :rules="[v => !!v || 'Password is required', v => v.length >= 6 || 'Minimum 6 characters required']"
              required
            ></v-text-field>
            <v-btn :disabled="!valid" type="submit" color="primary">Register</v-btn>
          </v-form>
          <v-alert v-if="errorMessage" type="error" dismissible>{{ errorMessage }}</v-alert>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
    return {
        email: '',
        password: '',
        errorMessage: '',
        valid: false  // Initially false, set to true when form is valid
    };
    },

    methods: {
    async handleRegister() {
        if (!this.valid) {
        this.errorMessage = "Please correct the errors before submitting.";
        return;
        }

        const payload = {
        email: this.email,
        password: this.password
        };

        // Use try-catch for async/await error handling
        try {
        const response = await this.$axios.post('/register', payload);
        if (response && response.status === 200) {
            // Assuming the backend returns 200 for success
            this.$router.push('/login');  // Redirect to login on successful registration
        } else {
            // Handle any non-200 responses here
            this.errorMessage = "Registration was not successful.";
        }
        } catch (error) {
        // More detailed error handling based on the error response
        if (error.response && error.response.data) {
            this.errorMessage = error.response.data.detail || 'Registration failed. Please try again.';
        } else {
            this.errorMessage = error.message || 'An unexpected error occurred.';
        }
        console.error('Error:', error);
        }
    }
    }
  };
  </script>
  