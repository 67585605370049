<template>
<v-card>
    <div class="pa-4 container-fluid">
                <v-row>
                    <v-col cols="12">
                        <div id="data-section" class="data-section">
                        <StrategyCryptos :strategyId="strategyId_detail" />
                        </div>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div id="data-section" class="data-section">
                        <StrategyOpenTrades :strategyId="strategyId_detail" />
                        </div>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div id="data-section" class="data-section">
                        <StrategyClosedTrades :strategyId="strategyId_detail" />
                        </div>

                    </v-col>
                </v-row>
                
    </div>
</v-card>
</template>
<script>
import StrategyCryptos from './StrategyCryptos.vue'
import StrategyOpenTrades from './StrategyOpenTrades.vue'
import StrategyClosedTrades from './StrategyClosedTrades.vue'

export default {
    components: {
        StrategyCryptos,
        StrategyOpenTrades,
        StrategyClosedTrades,
    },
    props: {
        strategyId_detail: [String, Number],
    },
}
</script>
<style>
.container-fluid {
    padding: 0 20px;
}

.main-container {
    display: flex;
    height: 70vh;
    flex-direction: row;
    transition: all 0.5s ease;
}

.data-section {
    height: 100%;
    width: 95%;
    overflow-y: auto;
    transition: width 0.5s ease;
}
</style>