<template>
    <div class="pa-4" style="border: solid; border-width: 1px;">
      <v-card class="mb-2">
      <v-row>
          <v-col>
            <div class="d-flex justify-space-around align-center">
              <h4>User Priveleges</h4>
            </div>
          </v-col>
      </v-row>
      </v-card>
      <v-card class="mb-2">
        <v-card-title class="headline embossed-background" primary-title>
          <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="4" class="d-flex align-center justify-center text-wrap"><b>Email</b></v-col>
              <v-col cols="3" class="d-flex align-center justify-center text-wrap"><b>App Access</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-center text-wrap"></v-col>
              <v-col cols="3" class="d-flex align-center justify-center text-wrap"><b>Admin</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-center text-wrap"></v-col>              
            </div>
          </v-col>
        </v-row>  
        </v-card-title>
        <v-row v-for="user in sortedUsers" :key="user.email">
          <v-card-text class="headline gray lighten-2 embossed-background">      
              <v-col cols="12">
              <div class="d-flex flex-row justify-space-between">
                <v-col cols="4" class="d-flex align-center justify-center text-wrap"><b>{{ user.email }}</b></v-col>
                <v-col cols="3" class="d-flex align-center justify-center text-wrap"><b>{{ user.is_authorized ? 'Yes' : 'No' }}</b></v-col>
                <v-col cols="1" class="d-flex align-center justify-start text-wrap">
                  <v-checkbox
                    v-model="user.is_authorized"
                    @change="toggleAuthorization(user)"
                    label="Toggle App Access"
                  ></v-checkbox></v-col>
                <v-col cols="3" class="d-flex align-center justify-center text-wrap"><b>{{ user.is_admin ? 'Yes' : 'No' }}</b></v-col>
                <v-col cols="1" class="d-flex align-center justify-start text-wrap">
                  <v-checkbox
                    v-model="user.is_admin"
                    @change="toggleAdminS(user)"
                    label="Toggle Admin Access"
                  ></v-checkbox></v-col>  
              </div>
              </v-col>
          </v-card-text>
        </v-row>

      </v-card>  
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    name: 'AdminDashboard',
    computed: {
      ...mapState(['users']),
      sortedUsers() {
          return this.$store.state.users.slice().sort((a, b) => {
            // Assuming the username is stored in the 'email' field
            const usernameA = a.email.toUpperCase(); // Convert to uppercase for case-insensitive sorting
            const usernameB = b.email.toUpperCase();
            
            if (usernameA < usernameB) {
              return -1;
            }
            if (usernameA > usernameB) {
              return 1;
            }
            return 0;
          });
        }
    },
    methods: {
      ...mapActions([
        'authorizeUser', // Vuex action to authorize/deauthorize a user
        'toggleAdmin'    // Vuex action to toggle admin status of a user
      ]),
      toggleAuthorization(user) {
        this.authorizeUser({ email: user.email, authorize: user.is_authorized });
      },
      toggleAdminS(user) {
        this.toggleAdmin({ email: user.email, isAdmin: user.is_admin });
      }
    },
    created() {
      // Fetch user data from backend when the component is created
      this.$store.dispatch('fetchUsers');
    }
  };
  </script>
  
  <style>
  
  </style>
