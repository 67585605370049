<template>
    <div>
      <h1>Welcome to the Dashboard</h1>
      <div v-if="isLoading">Loading...</div>
      <div v-else>
        <p>User Status: {{ $store.state.userStatus }}</p>
        <p v-if="$store.state.isAdmin">You are an admin.</p>

        <v-row>
            <v-col>
              <v-btn color="primary" @click="fetchUserStatus">Get Status</v-btn>
            </v-col>
          </v-row>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: true,
      };
    },
    mounted() {
      // Fetch user status and admin status from backend
      this.fetchUserStatus();
    },
    methods: {
        async fetchUserStatus() {
            try {
                // Retrieve authentication token from Vuex store or local storage
                const authToken = this.$store.state.token;

                // Make a request to fetch user status from the backend
                const response = await this.$axios.get('/', {
                headers: {
                    Authorization: `Bearer ${authToken}` // Include the token in the request headers
                }
                });

                // Update user status and admin status based on the response
                this.$store.state.isAuthorized = response.data.isAuthorized;
                this.$store.state.userStatus = this.$store.state.isAuthorized ? 'Authorized' : 'Awaiting Authorization';
                this.$store.state.isAdmin = response.data.isAdmin;
            } catch (error) {
                console.error('Error fetching user status:', error);
            } finally {
                this.isLoading = false;
            }
            }
    }
  };
  </script>
  