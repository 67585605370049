import axios from 'axios';

export const scanModule = {
    namespaced: true,
    state: {
        Scans:[],
    },
    mutations:{
        setScans(state,{scans}){
            //console.log(strategies)
            state.Scans = scans;
        },
    },
    actions: {
        async fetchScans({commit}) {
            try {
                const response = await axios.get('/scans');
                if (response && response.data) {
                    commit('setScans', { scans: response.data });
                } else {
                    console.error('No data received:', response);
                }
            } catch (error) {
                console.error('Error fetching strategies:', error);
            }
        },
        
    }
}