<template>
  <div class="pa-4" style="border: solid; border-width: 1px;">
    <v-card class="mb-2">
      <v-row>
          <v-col>
            <div class="d-flex justify-space-around align-center">
              <h4>Crypto List</h4>
            </div>
          </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-2">
      <v-row>
          <v-col>
            <select v-model="this.scanFilter">
                <option value="">All cryptos</option>
                <option v-for="(scan, index) in scans" :key="scan.id" :value=scan.name>{{ scan.description }}</option>
            </select>
          </v-col>
      </v-row>
    </v-card>
      
    <v-card class="mb-2">
      <v-card-title class="headline embossed-background" primary-title>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Exchange</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-center text-wrap"><b>Crypto</b></v-col>
              <v-col cols="8"></v-col>
              <v-col cols="1"></v-col>
            </div>  
          </v-col>
        </v-row>
      </v-card-title>
    
      <v-row 
            v-for="(crypto, index) in cryptos" 
            :key="crypto.id" 
            class="cursor-pointer crypto-row" 
            :aria-expanded="activeItems.includes(index) ? 'true' : 'false'" 
            tabindex="0"
        >
        <v-card-text 
          class="headline gray lighten-2 embossed-background" 
          role="tab"
          @click="toggle(index)" 
          @keyup.enter="toggle(index)"
          >
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ crypto.exchange }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-center text-wrap"><b>{{ crypto.symbol }}</b></v-col>
              <v-col cols="9"></v-col> <!-- Adjusted to occupy the space from the removed chevron -->
            </div>
          </v-col>
        </v-card-text>
        <transition-group name="fade" tag="v-row" class="full-width">
          <v-row  v-if="activeItems.includes(index)">
            <div class="expanded-full-width">
              <CryptoDetail :exchange_detail="crypto.exchange" :symbol_detail="crypto.symbol" :cryptoId_detail="crypto.id" :scanFilter_detail="this.scanFilter"/>
            </div>
          </v-row>        
        </transition-group>

      </v-row>
    
    </v-card>
  </div>
</template>


<script>
import { mapActions, mapState } from 'vuex';
import CryptoDetail from './CryptoDetail.vue';

export default {
  components: {
    CryptoDetail,
  },
  data() {
    return {
      activeItems: [],
      scanFilter: '',
    };
  },
  computed: {
    ...mapState(['cryptos']),
    ...mapState('scanModule', {
            scans: state => state.Scans || []
        }),
  },
  methods: {
    ...mapActions(['fetchCryptos']),
    ...mapActions('scanModule', [
        'fetchScans',
        ]),
    toggle(index) {
      //console.log('chevron clicked')
      const position = this.activeItems.indexOf(index);
      if (position === -1) {
        this.activeItems.push(index);  // If not active, make it active
      } else {
        this.activeItems.splice(position, 1);  // If already active, make it inactive
      }
    },
    getScans() {
            try {
                this.fetchScans();
            } catch (error) {
                console.error('Failed to fetch strategies:', error);
                //this.$toast.error('Error fetching strategies. Please try again.'); // Assuming using a toast plugin for user notifications
            }
        },
  },
  created() {
        this.getScans();
    },
  watch: {
      scanFilter: {
      handler: function(newFilter) {
        //console.log(newFilter);
        this.fetchCryptos({ filter: newFilter });
      },
      immediate: true  // This ensures the watcher is also triggered on component mount
    }
  }
}
</script>


  <style>
  /* Add your styles here */
.crypto-row {
  transition: background-color 0.3s ease;
}

.crypto-row:hover, .crypto-row:focus {
  background-color: #f5f5f5; /* Light grey background for hover and focus */
  outline: none; /* Removes default focus outline */
}

.crypto-row[aria-expanded="true"] {
  font-weight: bold; /* Optional: make text bold when expanded */
}

.full-width {
  width: 100%;
}

.expanded-full-width {
  width: 100%;
}


</style>
  