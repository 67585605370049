<template>
  <!-- TradingView Widget BEGIN -->
  <div class="tradingview-widget-container">
    <div :id="'tradingview-chart-' + chart" class="tradingview-widget-container__widget"></div>
  </div>
  <!-- TradingView Widget END -->
</template>

<script>
export default {
  name: "TradingViewChart",
  props: {
    exchange: {
      type: String,
      default: 'NASDAQ'
    },
    symbol: {
      type: String,
      default: 'AAPL'
    },
    timeframe: {
      type: String,
      default: '1'
    },
    chart: {
      type: String,
      default: '1'
    },
  },
  async mounted() {
    await this.loadTradingView();
  },
  methods: {
    loadTradingView() {
      const widgetId = `tradingview-chart-${this.chart}`;
      const widgetPlaceholder = document.getElementById(widgetId);
      widgetPlaceholder.replaceChildren(); // empty placeholder
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
      script.async = true;
      script.innerHTML = JSON.stringify({
          "autosize": false,
          "symbol": `${this.exchange}:${this.symbol.replace(/[^A-Z0-9]/ig, "")}`,
          "interval": this.timeframe,
          "timezone": "Europe/London",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "allow_symbol_change": true,
          "calendar": false,
          "container_id": widgetId
      });
      widgetPlaceholder.appendChild(script);
    }
  }
};
</script>

<style>
.tradingview-widget-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh; /* Adjust if needed */
    padding: 10px;
}

.tradingview-widget-container__widget {
    width: 90%; /* 80% of the container width */
    height: 90vh; /* 70% of the container height, adjust based on your layout */
    border: 1px solid #ccc; /* Optional */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional */
}

.tradingview-widget-container__footer {
    margin-top: 20px; /* Spacing between the chart and footer text */
}

.blue-text {
    color: #007BFF; /* Example blue color for links */
}
</style>
