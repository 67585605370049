<template>
    <div v-if="this.scanfilter != ''">
      <v-card class="mb-2">
        <v-card-title class="headline">
          Apply strategy to {{ cryptoId }}
          <v-row>
              <v-col>
                <select v-model="this.selectedStrategyId">
                    <option value="">All strategies</option>
                    <option v-for="(strategy, index) in strategies" :key="strategy.id" :value=strategy.id>{{ strategy.description }}</option>
                </select>
              </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" @click="onStrategyApply">Apply Strategy</v-btn>
            </v-col>
          </v-row>
          
        </v-card-title>
      </v-card>
    </div>
  </template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    cryptoId: {
      type: [String, Number],
      required: true
    },
    scanfilter: {
      type: [String, Number],
      required: true
    }, 
  },
  data() {
      return {
        selectedStrategyId: null,
      };
  },
  computed: {
      ...mapState('strategyModule', {
          strategies: state => state.Strategies || []
      }),
  },
  methods: {
    ...mapActions('strategyModule', [
      'fetchStrategies',
      'applyStrategy' // Assuming there's an action to apply the strategy
    ]),
    getStrategies() {
      try {
          this.fetchStrategies();
      } catch (error) {
          console.error('Failed to fetch strategies:', error);
          //this.$toast.error('Error fetching strategies. Please try again.'); // Assuming using a toast plugin for user notifications
      }
    },
    onStrategyApply() {
      
      if (this.scanfilter) {          
              this.applyStrategy({strategyId:this.selectedStrategyId, cryptoId:this.cryptoId, scanFilter:this.scanfilter}) // Dispatch an action to apply the strategy
      } else {
          console.error("No strategy selected!");
          //this.$toast.warning('Please select a strategy first.'); // Notify user to select a strategy
      }
    },
  },
  created() {
    this.getStrategies();
  },
}
</script>
