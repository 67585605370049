<template>
  <div>
    <CryptoSymbols />
  </div>
</template>
<script>
// @ is an alias to /src
import CryptoSymbols from '@/components/Cryptos/CryptoSymbols.vue'


export default {
  name: 'CryptoView',
  components: {
    CryptoSymbols
  }
}
</script>