<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <UserStatus />
  </div>
</template>

<script>
// @ is an alias to /src
import UserStatus from '@/components/Registration/Status.vue'


export default {
  name: 'HomeView',
  components: {
    UserStatus
  }
}
</script>
