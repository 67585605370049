<template>
    <div class="pa-4" style="border: solid; border-width: 1px;">
      <v-card class="mb-2">
        <v-row>
            <v-col>
              <div class="d-flex justify-space-around align-center">
                <h4>Crypto Data</h4>
              </div>
            </v-col>
        </v-row>
     </v-card>
     <v-card class="mb-2">
      <v-card-title class="headline embossed-background" primary-title>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Timestamp</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Open</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>High</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Low</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Close</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Volume</b></v-col>                            
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row v-if="cryptoPrices && cryptoPrices[cryptoId]" v-for="price in cryptoPrices[cryptoId].prices" :key="price.timestamp">
        <v-card-text class="headline gray lighten-2 embossed-background">
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>{{ new Date(price.timestamp).toLocaleDateString() }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>${{ price.open }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>${{ price.high }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>${{ price.low }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>${{ price.close }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>{{ price.volume }}</b></v-col>                            
            </div>
          </v-col>         
        </v-card-text>
      </v-row>
    </v-card>
     
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    props: {
      cryptoId: {
        type: [String, Number],
        required: true
      },
    },
    computed: {
      ...mapState('cryptoPricesModule', {
    cryptoPrices: state => state.cryptoPrices || 'Loading...'
      })
    },
    methods: {
      ...mapActions('cryptoPricesModule', [
        'fetchCryptoPrices'
      ]),
      getCryptoPrices() {
        this.fetchCryptoPrices({
            cryptoId: this.cryptoId,
            timeframe: '1d'  // Example timeframe, adjust as necessary
          }).catch(error => {
            // Handle error, e.g., show notification
            console.error('Failed to fetch prices:', error);
          });
          //console.log(this.cryptoPrices[this.cryptoId])
      }
    },
    async created() {
      if (!this.cryptoPrices) {
        await this.getCryptoPrices();
      }
    }, 
    async mounted() {
    // Ensures DOM is ready, good for DOM dependent operations
    await this.getCryptoPrices();
    }
  }
  </script>
  
  <style>
  /* Styles specific to this component */
  </style>
  