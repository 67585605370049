<template>
<div class="pa-4" style="border: solid; border-width: 1px;">
    <v-card class="mb-2">
      <v-row>
          <v-col>
            <div class="d-flex justify-space-around align-center">
              <h4>Strategies</h4>
            </div>
          </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-2">
        <v-card-title class="headline embossed-background" primary-title>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="3" class="d-flex align-center justify-start text-wrap"><b>Strategy Name</b></v-col>
              <v-col cols="9"></v-col>
            </div>  
          </v-col>
        </v-row>
      </v-card-title>
    
   

    <v-row 
            v-for="(strategy, index) in strategies" 
            :key="strategy.id" 
            class="cursor-pointer crypto-row" 
            :aria-expanded="activeItems.includes(index) ? 'true' : 'false'" 
            tabindex="0"
        >
        <v-card-text 
          class="headline gray lighten-2 embossed-background" 
          role="tab"
          @click="toggle(index)" 
          @keyup.enter="toggle(index)"
          >
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ strategy.name }}</b></v-col>
            </div>
          </v-col>
        </v-card-text>
        <transition-group name="fade" tag="v-row" class="full-width">
          <v-row  v-if="activeItems.includes(index)">
            <div class="expanded-full-width">
                <StrategyDetail :strategyId_detail="strategy.id"  />
            </div>
          </v-row>        
        </transition-group>

      </v-row>
    
    </v-card>
</div>



</template>
<script>
import { mapState, mapActions} from 'vuex';
import StrategyDetail from './StrategyDetail.vue';

export default {
    components: {
        StrategyDetail,
    },
    data() {
        return {
        activeItems: [],
        };
    },
    computed: {
        ...mapState('strategyModule', {
            strategies: state => state.Strategies || []
        }),
    },
    methods: {
        ...mapActions('strategyModule', [
        'fetchStrategies',
        ]),
        toggle(index) {
            //console.log('chevron clicked')
            const position = this.activeItems.indexOf(index);
            if (position === -1) {
                this.activeItems.push(index);  // If not active, make it active
            } else {
                this.activeItems.splice(position, 1);  // If already active, make it inactive
            }
        },
        getStrategies() {
            try {
                this.fetchStrategies();
            } catch (error) {
                console.error('Failed to fetch strategies:', error);
                this.$toast.error('Error fetching strategies. Please try again.'); // Assuming using a toast plugin for user notifications
            }
        },

    },
    created() {
        this.getStrategies();
    },

}
</script>
<style>
</style>