<template>
  <v-container>
    <v-card>
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="handleLogin">
          <v-text-field
            label="Email"
            v-model="email"
            :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
            required
          ></v-text-field>
          <v-text-field
            type="password"
            label="Password"
            v-model="password"
            :rules="[v => !!v || 'Password is required']"
            required
          ></v-text-field>
          <v-btn :disabled="!valid" type="submit" color="primary">Login</v-btn>
        </v-form>
        <v-alert v-if="errorMessage" type="error" dismissible>{{ errorMessage }}</v-alert>
        <!-- Register Button -->
        <v-btn text color="primary" @click="gotoRegister">No account? Register</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      valid: true
    };
  },
  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      const payload = {
        email: this.email,
        password: this.password
      };
      // Post request to backend
      try {
        const response = await this.$axios.post('/login', payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data && response.data.token) {
          const { token, isAuthorized, isAdmin } = response.data;
          this.login({ token, isAuthorized, isAdmin }); // Vuex action to store token and user roles
          console.log('Login successful. Redirecting to homepage...');
          this.$router.push('/'); // Redirect to homepage
        } else {
          console.error('Token not found in response data:', response.data);
          this.errorMessage = 'Login failed. Please try again.';
        }
      } catch (error) {
        console.error('Error:', error);
        this.errorMessage = error.response?.data?.detail || 'Login failed. Please try again.';
      }
    },
    gotoRegister() {
      this.$router.push('/register');  // Navigate to the registration page
    }
  }
};
</script>
