<template>
Strategy Open Trades Table
<div class="pa-4" style="border: solid; border-width: 1px;">
      <v-card class="mb-2">
        <v-row>
            <v-col>
              <div class="d-flex justify-space-around align-center">
                <h4>Open Trades</h4>
              </div>
            </v-col>
        </v-row>
     </v-card>
     <v-card class="mb-2">
      <v-card-title class="headline embossed-background" primary-title>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Timestamp</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Symbol</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Trade Type</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Position Type</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Amount</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Price</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Trade Message</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Opening Range High</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>Opening Range Low</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Profit/Loss</b></v-col>
              </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row v-if="Strategy_opentrades && Strategy_opentrades[strategyId]" v-for="cryptos in Strategy_opentrades[strategyId].cryptos" :key="cryptos.crypto_strategy_id">
        <v-card-text class="headline gray lighten-2 embossed-background">
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ new Date(cryptos.timestamp).toLocaleDateString() }}</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.symbol }}</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.trade_type }}</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.position_type }}</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.amount }}</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.price }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.trade_message }}</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.opening_range_high }}</b></v-col>
              <v-col cols="1" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.opening_range_low }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.profit_loss }}</b></v-col>
            </div>
          </v-col>         
        </v-card-text>
      </v-row>
    </v-card>
     
    </div>

</template>
<script>
import { mapState, mapActions} from 'vuex';

export default {
    props: {
        strategyId: [String, Number],
    },

    computed: {
        ...mapState('strategyModule', {
          Strategy_opentrades: state => state.Strategy_opentrades || [],            
        }),
    },
    methods: {
        ...mapActions('strategyModule', [
        'fetchstrategyopentrades',
        ]),
        getStrategyopentrades() {
            try {
                //console.log(this.strategyId)
                this.fetchstrategyopentrades({ strategyId: this.strategyId });
            } catch (error) {
                console.error('Failed to fetch cryptos for strategy:', error);
            }
        },        
    },
    mounted() {
        this.getStrategyopentrades();
    },
}


</script>
<style>
</style>