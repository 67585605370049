<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list dense>
        <v-list-item link v-for="item in filteredMenuItems" :key="item.title" :to="item.link">
          <v-list-item>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark>
      <v-btn icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>My Application</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Conditional Rendering of Login/Logout based on Authentication Status -->
      <v-btn v-if="!isLoggedIn" @click="gotoLogin" icon>
        <v-icon>mdi-login</v-icon>
      </v-btn>
      <v-btn v-else @click="handleLogout" icon>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <!-- Main content rendered here -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'App',
  data: () => ({
    drawer: false,
    menuItems: [
      { title: 'Home', icon: 'mdi-home', link: '/' },
      { title: 'About', icon: 'mdi-information', link: '/about' },
      { title: 'Contact', icon: 'mdi-phone', link: '/contact' },
      { title: 'Crypto', icon: 'mdi-bitcoin', link: '/cryptoview' },
      { title: 'Strategy', icon: 'mdi-wrench', link: '/strategyview' },
      { title: 'Admin', icon: 'mdi-pencil', link: '/admindashboard' }
    ]
  }),
  computed: {
    ...mapGetters([
      'isLoggedIn', // Assumes you have a getter named 'isLoggedIn' in Vuex
      'isAuthorized', // Assumes you have a getter named 'isAuthorized' in Vuex
      'isAdmin' // Assumes you have a getter named 'isAdmin' in Vuex
    ]),
    filteredMenuItems() {
      // Filter menu items based on user's authorization status and admin status
      return this.menuItems.filter(item => {
        if (item.title === 'About' || item.title === 'Contact') {
          // Only show About and Contact links if user is authorized
          return this.isAuthorized;
        } else if (item.title === 'Admin') {
          // Only show Admin link if user is an admin
          return this.isAdmin;
        } else {
          return true; // Always show other links
        }
      });
    }
  },
  methods: {
    ...mapActions([
      'logout' // Assumes you have an action named 'logout' in Vuex that clears the token
    ]),
    handleLogout() {
      this.logout();
      this.gotoLogin();
    },
    gotoLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style>
/* You can add global styles here */
/* Add your CSS styles here */
.headline {
      font-size: clamp(12px, 2vw, 14px); /* Adjusts headline font size dynamically */
      padding: 10px;
  }
  .embossed-background {
      background-color: #f7f7f7; /* The dominant light grey color from the image */
      box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2), /* darker shadow for depth */
                  inset -1px -1px 2px rgba(255, 255, 255, 0.7); /* lighter shadow for raised effect */
  }
  .full-width {
      margin-left:4%;
      width: 95%;
  }
</style>
