import axios from 'axios';

export const strategyModule = {
    namespaced: true,
    state: {
        Strategies:[],
        Strategy_cryptos:{},
        Strategy_opentrades:{},
        Strategy_closedtrades:{},
    },
    mutations:{
        setStrategies(state,{strategies}){
            //console.log(strategies)
            state.Strategies = strategies;
        },
        SET_STRATEGY_CRYPTOS(state, { StrategyId, cryptos }) {
            if (!state.Strategy_cryptos[StrategyId]) {
                state.Strategy_cryptos[StrategyId] = { cryptos: [] };
            }
            state.Strategy_cryptos[StrategyId].cryptos = cryptos;
            console.log(state.Strategy_cryptos[StrategyId].cryptos)
          },
          SET_STRATEGY_OPENTRADES(state, { StrategyId, cryptos }) {
            if (!state.Strategy_opentrades[StrategyId]) {
                state.Strategy_opentrades[StrategyId] = { cryptos: [] };
            }
            state.Strategy_opentrades[StrategyId].cryptos = cryptos;
            console.log(state.Strategy_opentrades[StrategyId].cryptos)
          },
          SET_STRATEGY_CLOSEDTRADES(state, { StrategyId, cryptos }) {
            if (!state.Strategy_closedtrades[StrategyId]) {
                state.Strategy_closedtrades[StrategyId] = { cryptos: [] };
            }
            state.Strategy_closedtrades[StrategyId].cryptos = cryptos;
            console.log(state.Strategy_closedtrades[StrategyId].cryptos)
          },
    },
    actions: {
        async fetchStrategies({commit}) {
            try {
                const response = await axios.get('/strategies');
                if (response && response.data) {
                    commit('setStrategies', { strategies: response.data });
                } else {
                    console.error('No data received:', response);
                }
            } catch (error) {
                console.error('Error fetching strategies:', error);
            }
        },
        async fetchstrategycryptos({commit}, { strategyId }) {
            try {
                console.log(strategyId);
                const response = await axios.get(`/strategy_crypto/${parseInt(strategyId)}`);
                if (response && response.data) {
                    commit('SET_STRATEGY_CRYPTOS', { StrategyId: strategyId, cryptos: response.data });
                    return response
                } else {
                    console.error('No data received:', response);
                    return response;
                }
            } catch (error) {
                console.error('Error fetching strategies:', error);
                throw error;
            }
        },
        async deletestrategycrypto({ dispatch }, { cryptostrategy_Id }) {
            try {
                const payload = { cryptostrategyId: cryptostrategy_Id, };
                console.log(payload);
                const response = await axios.post('/delete_strategy_crypto', payload);
                if (response) {
                    console.log('Response:', response);
                    return response;
                } else {
                    console.error('No data received:', response);
                    return response;
                }
            } catch (error) {
                console.error('Error deleting crypto:', error);
                throw error;
            }
        },        
        async fetchstrategyopentrades({commit}, { strategyId }) {
            try {
                //console.log(strategyId);
                const response = await axios.get(`/get_strategy_open_trades/${parseInt(strategyId)}`);
                if (response && response.data) {
                    commit('SET_STRATEGY_OPENTRADES', { StrategyId: strategyId, cryptos: response.data });
                } else {
                    console.error('No data received:', response);
                }
            } catch (error) {
                console.error('Error fetching strategies:', error);
            }
        },        
        async fetchstrategyclosedtrades({commit}, { strategyId }) {
            try {
                //console.log(strategyId);
                const response = await axios.get(`/get_strategy_closed_trades/${parseInt(strategyId)}`);
                if (response && response.data) {
                    commit('SET_STRATEGY_CLOSEDTRADES', { StrategyId: strategyId, cryptos: response.data });
                } else {
                    console.error('No data received:', response);
                }
            } catch (error) {
                console.error('Error fetching strategies:', error);
            }
        },        
        async applyStrategy({dispatch}, { strategyId, cryptoId, scanFilter }) {
            try {
                console.log('Apply strategy', strategyId)
                console.log('To crypto', cryptoId)
                console.log('With scan filter', scanFilter)
                const response = await axios.post('/applystrategy', {strategyId : strategyId, cryptoId: cryptoId, scanFilter: scanFilter });
                if (response) {
                    console.log('Response:', response)
                } else {
                    console.error('No data received:', response);
                }
            } catch (error) {
                console.error('Error applying strategy:', error);
            }
        },
        
    }
}