<template>
<div class="pa-4" style="border: solid; border-width: 1px;">
      <v-card class="mb-2">
        <v-row>
            <v-col>
              <div class="d-flex justify-space-around align-center">
                <h4>Cryptos in Strategy</h4>
              </div>
            </v-col>
        </v-row>
     </v-card>
     <v-card class="mb-2">
      <v-card-title class="headline embossed-background" primary-title>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Symbol</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Exchange</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>Scan</b></v-col>              
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b></b></v-col>
              </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row v-if="Strategy_cryptos && Strategy_cryptos[strategyId]" v-for="cryptos in Strategy_cryptos[strategyId].cryptos" :key="cryptos.crypto_strategy_id">
        <v-card-text class="headline gray lighten-2 embossed-background">
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.symbol }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.exchange }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b>{{ cryptos.scan_description }}</b></v-col>
              <v-col cols="2" class="d-flex align-center justify-start text-wrap"><b><v-btn color="primary" @click="CryptoRemove(cryptos.crypto_strategy_id)">Delete Crypto</v-btn></b></v-col>
            </div>
          </v-col>         
        </v-card-text>
      </v-row>
    </v-card>
     
    </div>

</template>
<script>
import { mapState, mapActions} from 'vuex';

export default {
    props: {
        strategyId: [String, Number],
    },
    computed: {
        ...mapState('strategyModule', {
          Strategy_cryptos: state => state.Strategy_cryptos || 'Loading...'
        }),
    },
    watch: {
      Strategy_cryptos: {
            handler(newVal, oldVal) {
                // React to changes in strategy_bo_crypto_long
                console.log('Strategy crypto list changed', newVal);
                // Additional actions here
            },
            deep: true
        },
    },
    methods: {
        ...mapActions('strategyModule', [
        'fetchstrategycryptos',
        'deletestrategycrypto'
        ]),
        async getStrategycryptos() {
            try {
                //console.log(this.strategyId)
                await this.fetchstrategycryptos({ strategyId: this.strategyId });
            } catch (error) {
                console.error('Failed to fetch cryptos for strategy:', error);
                //this.$toast.error('Error fetching cryptos for strategy. Please try again.'); // Assuming using a toast plugin for user notifications
            }
        },
        async CryptoRemove(crypto_strategy_id) {
            //console.log(crypto_strategy_id)
            try {
                //console.log(this.strategyId)
                await this.deletestrategycrypto({ cryptostrategy_Id: crypto_strategy_id });
                await this.getStrategycryptos();        
            } catch (error) {
                console.error('Failed to delete crypto for strategy:', error);
                //this.$toast.error('Error deleting crypto for strategy. Please try again.'); // Assuming using a toast plugin for user notifications
            }
        }        
    },
    mounted() {
        this.getStrategycryptos();
    },

}
</script>
<style>
</style>